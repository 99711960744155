@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
 
}
body{
    overflow: hidden;
}
.gradient-bg{
    background: linear-gradient(112deg, #7b76ff, #df6fde, #ff7ab8, #ff999b, #ffbe91, #ffe1a0, #ffffc3);
    background-size: 200%;
    animation: bgAnimation 60s infinite alternate;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background:linear-gradient(to bottom, #f1F4F9, #DFF1FF); */
    /* background: linear-gradient(112deg, #7b76ff, #df6fde, #ff7ab8, #ff999b, #ffbe91, #ffe1a0, #ffffc3);
    background-size: 200%;
    animation: bgAnimation 60s infinite alternate; */
}

/* .container .bg-color{
    position: absolute;
    filter:blur(150px);
}
.container .bg-color:nth-child(1){
    top: -50%;
    width: 70%;
    height: 90%;
    background: #ff359b;
}
.container .bg-color:nth-child(2){
    bottom: -30%;
    width: 80%;
    height: 80%;
    right: 30%;
    background: #fffd87;
}
.container .bg-color:nth-child(3){
    bottom: -15%;
    right:35%;
    width: 40%;
    height: 40%;
    background: #00d2ff;
} */

@keyframes bgAnimation{
    0%{
        background-position: left;
    }
    100%{
        background-position: right;
    }
}

.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.1);
    /* backdrop-filter: blur(5px); */
    width: 110vh;
    height: 70vh;
    border-radius: 1em;
    box-shadow: 0 0.25em .45em rgba(0,0,0,0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 2em 3em;
}

iframe{
    position:absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
}

.box {
   position:relative;
}

.box .square{
    position: absolute;
    backdrop-filter: blur(5px);
    box-shadow: 0 0.25em .45em rgba(0,0,0,0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255,255,255,0.1);
    border-radius: 1em;
    animation: movingBox 10s infinite;
    animation-delay: calc(1s * var(--i));
    overflow: hidden;
}
.box .square::after{
    position: absolute;
    content: '';
    width:100px;
    height: 400px;
    background: rgba(255,255,255,.5);
    top:0;
    left:0;
    transform:skewX(45deg) translateX(400px);
    transition:.5s;
}
.box .square:hover::after{
    transform:skewX(45deg) translateX(-400px);
}
@keyframes movingBox {
    0%, 100%{
        transform: translateY(-40px);
    }
    50%{
        transform: translateY(40px);
    }
}
.box .square:nth-child(1){
    top: -150px;
    right: -180px;
    width: 200px;
    height: 200px;
}
.box .square:nth-child(2){
    top: 210px;
    left: -160px;
    width: 180px;
    height: 180px;
}
.box .square:nth-child(3){
    bottom: -90px;
    right: -100px;
    width: 120px;
    height: 120px;
}
.box .square:nth-child(4){
    bottom: -120px;
    left: 100px;
    width: 100px;
    height: 100px;
}
.box .square:nth-child(5){
    top: -160px;
    left: 180;
    width: 140px;
    height: 140px;
}
.playlist-container{
    padding: 0 !important;
}
.playlist-item{
    position: relative;
    backdrop-filter: blur(5px);
    border-radius: .5em;
    box-shadow: 0 0.25em .45em rgba(0,0,0,0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: .5em;
    transition: .5s;
    overflow: hidden;
}

.playlist-item:hover,
.playlist-item.active{
    transform:scale(1.05) translateX(-20px);
    background-color: #ffffc3;
}
.playlist-item:hover::before{
    transform:skewX(45deg) translateX(-300%);
}
.playlist-item::before{
    content: '';
    position:absolute;
    width: 40%;
    height: 100%;
    background: rgba(255,255,255,.5);
    top:0;
    left:0;
    transform:skewX(45deg) translateX(300%);
    transition:.75s;
}